import PropTypes from "prop-types"
import React, { useState } from "react"

const Header = ({ siteTitle }) => {
  const [email, setEmail] = useState("")
  const [modal, toggleModal] = useState(false)
  return (
    <header className="w-auto h-screen">
      <div className="pt-8 md:pt-24">
        <h1 className="header-title text-4xl md:text-5xl text-center">
          Feeling
          <span className="header-random-word text-4xl md:text-5xl">
            hungry
          </span>
          ?
        </h1>
        <p className="header-subtitle text-center content-center md:text">
          Looking for inspiration in your fridge? Let us handle it.
        </p>
      </div>
      <div className="header-cta">
        <span className="header-cta-text text-xs block h-auto w-1/2 overflow-visible md:float-left md:w-1/4 md:text">
          Spend less time searching for recipes on the web and more time
          browsing the food you want.
        </span>
        <form
          action="https://gmail.us19.list-manage.com/subscribe/post?u=b39255f3230515ae0c1035794&amp;id=b5204a2cd3"
          method="post"
          id="mc-embedded-subscribe-form-two"
          name="mc-embedded-subscribe-form"
          className="validate content-center"
          target="_blank"
          noValidate
        >
          <div className="flex">
            <a
              href="https://app.tastyfridge.com"
              target="_blank"
              className="flex items-center bg-blue-600 button px-4 py-2 h-12 shadow-md mx-2 text-center font-bold"
              onClick={() => {
                window.dataLayer.push({
                  event: "inputBarClick",
                  category: "demo",
                  action: "click",
                  label: "google analytics ",
                })
              }}
            >
              <span className="flex-1">Try Demo</span>
            </a>
            {!modal ? (
              <button
                className="button-bg button px-4 py-2 h-12 shadow-md mr-3 text-center font-semibold"
                onClick={() => toggleModal(true)}
              >
                Notify Me when it's Live
              </button>
            ) : (
              <div
                id="mc_embed_signup_scroll_two"
                className="w-auto content-center pb-2"
              >
                <input
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  name="EMAIL"
                  id="mce-EMAIL-two"
                  placeholder="email address"
                  required
                  className="input-bg email shadow-lg h-12 pb-1 px-2 focus:outline-none text-center header-cta-email w-3/4"
                  onClick={e => {
                    e.preventDefault()
                    window.dataLayer = window.dataLayer || []
                    window.dataLayer.push({
                      event: "inputBarClick",
                      category: "header form",
                      action: "click",
                      label: "google analytics ",
                    })
                  }}
                />
                <div
                  style={{ position: "absolute", left: "-5000px" }}
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_b39255f3230515ae0c1035794_b5204a2cd3"
                    tabIndex="-1"
                    value=""
                    readOnly
                  />
                </div>
                <div className="clear w-3/4">
                  <input
                    type="submit"
                    value="Sign Up"
                    name="Sign Up"
                    id="mc-embedded-subscribe-two"
                    className="button-bg button px-4 py-2 h-12 shadow-md mr-3 text-center font-bold w-full"
                    readOnly
                    onClick={e => {
                      window.dataLayer = window.dataLayer || []
                      window.dataLayer.push({
                        event: "formSubmit",
                        category: "header form",
                        action: "click",
                        label: "google analytics ",
                      })
                    }}
                  />
                  <p className="header-cta-readmore w-full text-sm">
                    <b>read more</b> bellow
                  </p>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </header>
  )
}
/*
const oldHeader = ({ siteTitle }) => (
  <header
    style={{
      background: `rebeccapurple`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
  </header>
)
*/
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `TastyFridge`,
}

export default Header
